/**
 * name: @fireworks-js/react
 * version: 2.10.6
 * author: Vitalij Ryndin (https://crashmax.ru)
 * homepage: https://fireworks.js.org
 * license MIT
 */
"use client";
import n, { useRef as u, useImperativeHandle as p, useEffect as d } from "react";
import { Fireworks as f } from "fireworks-js";
const m = n.forwardRef(
  ({ children: a, options: c, autostart: s = !0, ...i }, o) => {
    const t = u(null), r = u(null);
    return p(o, () => ({
      get isRunning() {
        return r.current.isRunning;
      },
      get currentOptions() {
        return r.current.currentOptions;
      },
      start() {
        r.current.start();
      },
      launch(e) {
        r.current.launch(e);
      },
      stop() {
        r.current.stop();
      },
      async waitStop() {
        await r.current.waitStop();
      },
      pause() {
        r.current.pause();
      },
      clear() {
        r.current.clear();
      },
      updateOptions(e) {
        r.current.updateOptions(e);
      },
      updateSize(e) {
        r.current.updateSize(e);
      },
      updateBoundaries(e) {
        r.current.updateBoundaries(e);
      }
    })), d(() => (r.current || (r.current = new f(t.current, c)), s && r.current.start(), () => {
      r.current.stop();
    }), []), /* @__PURE__ */ n.createElement(
      "div",
      {
        ref: t,
        ...i
      },
      a
    );
  }
);
export {
  m as Fireworks,
  m as default
};
